.playingCard {

  margin: 25px;
  display: inline-block;
  
  img {
    max-width: 150px;
    height: auto;

    &:hover {
      transform:scale(2.5);
    }
  }
}

.modal-content-dark {
  .modal-content { 
    background-color: grey;
  }
}
.Screen_Collection {
  .Row_Heading {
    vertical-align: center;
    display: flex;
    align-items: center;

    .right {
      text-align: right;
    }
  }

  .Row_Filter {

  }

  .Row_Collection {

  }
}
body {
  background-color: rgb(53, 53, 53);
  color: white;
}

#PrimaryContainer {
  margin-top: 50px;
}

.modal-content {
  background-color: slategray;
}
#Screen_Profile {

  *:hover {
    border: solid 1px red;
  }

  .profilePicture {
    // background-color: greenYellow;
    min-width: 100px;
    min-height: 100px;
    width: 100%;
    border-radius: 50%;
  }
}
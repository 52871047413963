.ScryFallSymbol {
  
    .sm {
      width: 20px;
      height: auto;
    }

    .md {
      width: 30px;
      height: auto;
    }

    .lg {
      width: 40px;
      height: auto;
    }
}
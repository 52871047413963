.CardFilterToolbar {
  
  .nav-item {
    margin : 0px 5px;
    // border: solid 1px lime !important;
    color : white;

    a {
      color : grey;
    }
    
    .active {
      transition : all 500ms ease-in-out !important;
      color: white;
      background-color: slategray;
    }
  }

}
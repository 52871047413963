#AddToCollectionModal {

  .BackToSelectEntryMethod {
    justify-self : flex-start;
  }
  
  .selectEntryMethod {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-flow: row;
    align-items: center;
    justify-items: center;

    .square {
      display: grid;
      align-items: center;
      justify-items: center;
      width: 80%;
      height: auto;
      background-color: rgba(255,255,255,.2);
      color: black;
      cursor: pointer;
      border-radius: 15px;
      transition: all .5s ease-in-out;

      &:hover {
        background-color : rgba(255,255,255,.8);
      }
  
      .icon {
        font-size : 3rem;
      }
  
      .label {
        background-color: transparent;
        user-select: none;
      }
  
    }
  }

  .Search {
    .CardGridResults {
      padding: 25px;
      display: grid;
      // grid-auto-flow: row;
      gap: 15px;
      align-items: center;
      justify-items: center;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;

      img {
        width: 200px;
        height: auto;
      }

      .card {
        color: black;
        width: 100%;
        height: auto;

        img {

        width: 100%;
        height: auto;
        }

        .showOnHover {
          transition: all .5s ease-in-out;
          opacity: 0;
          &:hover {
            background-color: rgba(10,10,10, .7);
            opacity: 1;
          }
        }
      }
    }
  }


}